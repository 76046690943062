import React, { useState, useEffect } from 'react';
import './Dashboard.css';

const UsersContent = ({ users, onViewUserDetails, fetchProductsCount, userProductsCount }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState('all');
    const [userProductCounts, setUserProductCounts] = useState({});
    const [showFilters, setShowFilters] = useState(true);
    const usersPerPage = 30;

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;

    useEffect(() => {
        setUserProductCounts(userProductsCount);
    }, [userProductsCount]);

    const countAinsounds = () => {
        fetchProductsCount();
        setShowFilters(true);
    };

    const filteredUsers = users.filter(user =>
        user.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const allUsers = filteredUsers
        .sort((a, b) => {
            const aCounts = userProductCounts[a.uid] || { songs: 0, cards: 0 };
            const bCounts = userProductCounts[b.uid] || { songs: 0, cards: 0 };
            return (bCounts.songs + bCounts.cards) - (aCounts.songs + aCounts.cards);
        });
    const clientUsers = filteredUsers
        .filter(user => {
            const counts = userProductCounts[user.uid] || { songs: 0, cards: 0 };
            return counts.songs > 0 || counts.cards > 0;
        })
        .sort((a, b) => {
            const aCounts = userProductCounts[a.uid] || { songs: 0, cards: 0 };
            const bCounts = userProductCounts[b.uid] || { songs: 0, cards: 0 };
            return (bCounts.songs + bCounts.cards) - (aCounts.songs + aCounts.cards);
        });
    const inactiveUsers = filteredUsers.filter(user => {
        const counts = userProductCounts[user.uid] || { songs: 0, cards: 0 };
        return counts.songs === 0 && counts.cards === 0;
    });

    const filteredByRadio = (filter === 'all') ? allUsers : (filter === 'clients') ? clientUsers : inactiveUsers;

    const currentUsers = filteredByRadio.slice(indexOfFirstUser, indexOfLastUser);
    const totalPages = Math.ceil(filteredByRadio.length / usersPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            window.scrollTo(0, 0);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            window.scrollTo(0, 0);
        }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reiniciar a la primera página al hacer una búsqueda
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
        setCurrentPage(1); // Reiniciar a la primera página al cambiar el filtro
    };

    return (
        <div>
            <div className='page-title'>Usuarios</div>
            <table className="search-individual-table">
                <tbody>
                    <tr>
                        <td className="search-container-cell">
                            <div className="search-container">
                                <div className="search-icon">
                                    <div className="profile-image"></div>
                                </div>
                                <input 
                                    type="text" 
                                    placeholder="Nombre o email" 
                                    className="search-input" 
                                    value={searchTerm} 
                                    onChange={handleSearch}
                                />
                            </div>
                        </td>
                        <td>
                            <button className="search-button">Buscar</button>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                        {showFilters && (
                            <div className="filter-container">
                                <button style={{ marginTop: '20px', marginBottom: '20px' }} className="modal-button modal-button-blue" onClick={countAinsounds}>Contar ainsounds</button>
                                <label className="filter-label">
                                    <input 
                                        type="radio" 
                                        name="filter" 
                                        value="all" 
                                        checked={filter === 'all'} 
                                        onChange={handleFilterChange}
                                        style={{marginTop: '16px'}}
                                    />
                                    Ver todos
                                </label>
                                <label className="filter-label">
                                    <input 
                                        type="radio" 
                                        name="filter" 
                                        value="clients" 
                                        checked={filter === 'clients'} 
                                        onChange={handleFilterChange}
                                        style={{marginTop: '16px'}}
                                    />
                                    Ver clientes
                                </label>
                                <label className="filter-label">
                                        <input 
                                        type="radio" 
                                        name="filter" 
                                        value="inactive" 
                                        checked={filter === 'inactive'} 
                                        onChange={handleFilterChange}
                                        style={{marginTop: '16px'}}
                                    />
                                    Ver inactivos
                                </label>
                            </div>
                        )}  
                        </td>
                    </tr>
                </tbody>
            </table>
            
            <div className="results-content">
                {currentUsers.map((user, index) => (
                    <div key={index} className="user-container">
                        <div className="user-info">
                            <strong className='vertical-padding-lbl'>{user.nombre}</strong>
                            <span className='vertical-padding-lbl'>{user.email}</span>
                            <span className="user-status">
                                {userProductCounts[user.uid] ? userProductCounts[user.uid].songs + userProductCounts[user.uid].cards : 0} sonidos activados
                            </span>
                        </div>
                        <button className="user-details-btn" onClick={() => onViewUserDetails(user)}>
                        </button>
                    </div>
                ))}
            </div>
            <div className="pagination">
                <button 
                    className={`pagination-btn ${currentPage === 1 ? 'disabled' : ''}`} 
                    onClick={handlePrevPage}
                >
                    &lt;
                </button>
                <button 
                    className={`pagination-btn ${currentPage === totalPages ? 'disabled' : ''}`} 
                    onClick={handleNextPage}
                >
                    &gt;
                </button>
                <div className="pagination-info">
                    {`${indexOfFirstUser + 1} - ${indexOfLastUser} de ${filteredByRadio.length}`}
                </div>
            </div>
        </div>
    );
};

export default UsersContent;
