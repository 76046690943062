import React from 'react';
import './Dashboard.css';
import successImage from './assets/success.png';
import errorImage from './assets/error.png';

const Modal = ({ show, onClose, success, message }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <img 
          src={success ? successImage : errorImage} 
          alt={success ? "Éxito" : "Error"} 
          style={{ height: "200px", margin: '0 auto', display: 'block' }}
        />
        <h2 style={{ textAlign: 'center' }}>{success ? "¡Éxito!" : "Error"}</h2>
        <p style={{ textAlign: 'center' }}>{message}</p>
        <div className="modal-buttons">
          <button className="modal-button modal-button-blue" onClick={onClose}>Entendido</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
