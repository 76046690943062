import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import Modal from './Modal';
import './Dashboard.css';
import './LoadingOverlay.css';

const EmailSender = ({ basicUsers, userProductCounts }) => {
  const [content, setContent] = useState('');
  const [subject, setSubject] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalSuccess, setModalSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState('all');
  const [filter2, setFilter2] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]); // Estado para los usuarios seleccionados
  const [prevSelectedUsers, setPrevSelectedUsers] = useState([]); // Estado para los usuarios seleccionados previamente (en caso de que el usuario cancele sus útlimos cambios)
  const [selectAll, setSelectAll] = useState(false); // Estado para "seleccionar todo"
  const [prevSelectAll, setPrevSelectAll] = useState(false); // Estado original para "seleccionar todo" (en caso de que el usuario cancele sus útlimos cambios)
  const [sentEmails, setSentEmails] = useState(0);

  const handleSendToAll = () => {
    handleSendEmail();
  };

  // Manejar selección de todos los usuarios
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedUsers([]); // Deselecciona todos
    } else {
      setSelectedUsers(filteredByRadio2);
    }
    setSelectAll(!selectAll); // Cambia el estado de "seleccionar todo"
  };

  // Manejar selección individual de un usuario
  const handleSelectUser = (seluser) => {
    let updatedSelectedUsers = [];

    if (selectedUsers.includes(seluser)) {
      // Si ya está seleccionado, lo removemos
      updatedSelectedUsers = selectedUsers.filter(user => user !== seluser);
      setSelectAll(false); // Si se desselecciona, desactiva "seleccionar todo"
    } else {
      // Si no está seleccionado, lo agregamos
      updatedSelectedUsers = [...selectedUsers, seluser];

      // Si todos los usuarios están seleccionados manualmente, activa "seleccionar todo"
      if (updatedSelectedUsers.length === basicUsers.length) {
        setSelectAll(true);
      }
    }
    setSelectedUsers(updatedSelectedUsers);
  };

  const handleSendEmail = async () => {
    let usersToSend = filteredByRadio;

    console.log(usersToSend);

    setIsLoading(true);

    try {
      let sentCount = 0;
      for (const user of usersToSend) {
        const emailContent = `
          <div style="width:100%;max-width:600px;margin:0 auto;">
            <center>
            <img src="cid:titleImage" alt="Imagen Aincard" style="width:100%;height:200px;object-fit: cover;">
            </center>
            <div style="height:20px;"></div>
            <div style="font-size:24px;font-weight:bold;text-align:center;">¡Hola ${user.soloNombre}!</div>
            <div style="height:20px;"></div>
            <div style="font-size:16px;text-align:center;">
              ${content}
            </div>
            <div style="height:20px;"></div>
            <center>
            <img src="cid:signatureImage" alt="Imagen inferior" style="display:block;margin:0 auto;height:75px;">
            </center>
            <div style="height:50px;"></div>
            <center>
              <a href="https://aincard-iam.com/docs/help-center.html" style="font-size:12px;">Centro de ayuda</a>
            </center>
            <center>
              <a href="https://aincard-iam.com/email-privacy-policy.html" style="font-size:12px;">Aviso de privacidad</a>
            </center>
            <div style="height:25px;"></div>
            <center>
              <div style="font-size:12px;">Si tienes alguna duda, contáctanos a contacto@aincard-iam.com</div>
            </center>
            <center>
              <div style="font-size:12px;">Este correo es enviado de forma automática, no respondas este mensaje.</div>
            </center>
          </div>
        `;

        const response = await axios.post('https://us-central1-aincard-344e4.cloudfunctions.net/adminBackend/sendEmail', {
          emailList: [user.email], // Enviar correo individualmente
          subject,
          content: emailContent,
        }, {
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (response.status === 200) {
          console.log(`Correo enviado a ${user.email}`);
          sentCount += 1;
          setSentEmails(sentCount)







        } else {
          throw new Error(`Error al enviar el correo a ${user.email}`);
        }
      }
      setModalSuccess(true);
      setModalMessage('Correos enviados exitosamente');
    } catch (error) {
      console.error('Error al enviar los correos:', error);
      setModalSuccess(false);
      setModalMessage('Error al enviar los correos');
    } finally {
      setIsLoading(false);
      setShowModal(true);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredUsers = basicUsers.filter(user =>
    user.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const allUsers = basicUsers
      .sort((a, b) => {
          const aCounts = userProductCounts[a.uid] || { songs: 0, cards: 0 };
          const bCounts = userProductCounts[b.uid] || { songs: 0, cards: 0 };
          return (bCounts.songs + bCounts.cards) - (aCounts.songs + aCounts.cards);
      });
  const clientUsers = basicUsers
      .filter(user => {
          const counts = userProductCounts[user.uid] || { songs: 0, cards: 0 };
          return counts.songs > 0 || counts.cards > 0;
      })
      .sort((a, b) => {
          const aCounts = userProductCounts[a.uid] || { songs: 0, cards: 0 };
          const bCounts = userProductCounts[b.uid] || { songs: 0, cards: 0 };
          return (bCounts.songs + bCounts.cards) - (aCounts.songs + aCounts.cards);
      });
  const inactiveUsers = basicUsers.filter(user => {
      const counts = userProductCounts[user.uid] || { songs: 0, cards: 0 };
      return counts.songs === 0 && counts.cards === 0;
  });

  const allUsers2 = filteredUsers
      .sort((a, b) => {
          const aCounts = userProductCounts[a.uid] || { songs: 0, cards: 0 };
          const bCounts = userProductCounts[b.uid] || { songs: 0, cards: 0 };
          return (bCounts.songs + bCounts.cards) - (aCounts.songs + aCounts.cards);
      });
  const clientUsers2 = filteredUsers
      .filter(user => {
          const counts = userProductCounts[user.uid] || { songs: 0, cards: 0 };
          return counts.songs > 0 || counts.cards > 0;
      })
      .sort((a, b) => {
          const aCounts = userProductCounts[a.uid] || { songs: 0, cards: 0 };
          const bCounts = userProductCounts[b.uid] || { songs: 0, cards: 0 };
          return (bCounts.songs + bCounts.cards) - (aCounts.songs + aCounts.cards);
      });
  const inactiveUsers2 = filteredUsers.filter(user => {
      const counts = userProductCounts[user.uid] || { songs: 0, cards: 0 };
      return counts.songs === 0 && counts.cards === 0;
  });

  const filteredByRadio = (filter === 'all') ? allUsers : (filter === 'clients') ? clientUsers : (filter === 'noclients') ? inactiveUsers : selectedUsers;
  const filteredByRadio2 = (filter2 === 'all') ? allUsers2 : (filter2 === 'clients') ? clientUsers2 : (filter2 === 'noclients') ? inactiveUsers2 : selectedUsers;

  const modules = {
    toolbar: [
      ['bold', 'color'], // solo negritas y color
      ['clean'] // quitar formato
    ],
  };

  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      .ql-editor {
        font-size: 16px;
        text-align: center;
      }
    `;
    document.head.appendChild(style);
  }, []);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    if (e.target.value !== "customized") {
      setSelectedUsers([]);
      setSelectAll(false);
    }
  };

  const handleFilterChange2 = (e) => {
    setFilter2(e.target.value);
    setSelectedUsers([]);
    setSelectAll(false);
  };

  const cancelCustomSelection = () => {
    setShowActivateModal(false);
    setSelectedUsers(prevSelectedUsers);
    setSelectAll(prevSelectAll);
  };

  const saveCustomSelection = () => {
    setShowActivateModal(false);
    setPrevSelectedUsers(selectedUsers);
    setPrevSelectAll(selectAll);
  };

  return (
    <div>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div style={{color: 'white', fontWeight: 'bold'}}>{sentEmails} de {filteredByRadio.length} correos enviados</div>
          <div style={{color: 'white', fontWeight: 'bold'}}>No cierres el sitio, espera por favor</div>
        </div>
      )}
      <div className='page-title'>Aincard Mailing</div>
      <div className='page-desc'>En esta sección podrás enviar correos electrónicos a los usuarios de tu aplicación. Elige los <b>destinatarios</b> del correo e ingresa el <b>asunto</b> y <b>contenido</b> del correo.</div>
      <div className='page-desc2'>Enviar a:</div>

      <div className="filter-container">
        <label className="filter-label">
            <input 
                type="radio" 
                name="filter" 
                value="all" 
                checked={filter === 'all'} 
                onChange={handleFilterChange}
                style={{marginTop: '16px'}}
            />
            Todos
        </label>
        <label className="filter-label">
            <input 
                type="radio" 
                name="filter" 
                value="clients" 
                checked={filter === 'clients'} 
                onChange={handleFilterChange}
                style={{marginTop: '16px'}}
            />
            Clientes
        </label>
        <label className="filter-label">
                <input 
                type="radio" 
                name="filter" 
                value="noclients" 
                checked={filter === 'noclients'} 
                onChange={handleFilterChange}
                style={{marginTop: '16px'}}
            />
            No clientes
        </label>
        <label className="filter-label">
                <input 
                type="radio" 
                name="filter" 
                value="customized" 
                checked={filter === 'customized'} 
                onChange={handleFilterChange}
                style={{marginTop: '16px'}}
            />
            Personalizado
        </label>
        {
          filter === 'customized' && (<button style={{ marginTop: '20px', marginBottom: '20px' }} className="modal-button modal-button-blue" onClick={() => setShowActivateModal(true)}>Elegir usuarios</button>)
        }
      </div>
      
      <div className="destinataries-container" style={{ marginTop: '30px'}}>
        <div className='dest-container' style={{paddingLeft: '30px', paddingRight: '30px', paddingTop: '0px', paddingBottom: '0px'}}>
          <div className='title-dest-container'>
            <p style={{paddingRight: '30px', color: '#757575', alignItems: 'center'}}>
              Destinatarios
            </p>
          </div>
          <p className='scrollable-dest' style={{color: '#A5A5A5'}}>
            {filteredByRadio.map((user) => (
              (user.nombre + " (" + user.email + "), ")
            ))}
          </p>
        </div>
      </div>

      <div className="search-container" style={{ marginTop: '30px' }}>
        <input 
          type="text" 
          placeholder="Asunto" 
          className="search-input" 
          value={subject} 
          onChange={(e) => setSubject(e.target.value)}
          style={{ marginLeft: '20px' }}
        />
      </div>

      <ReactQuill
        value={content}
        onChange={setContent}
        modules={modules}
        style={{ height: '50vh', backgroundColor: 'white', paddingBottom: 40, marginTop: 30}}
      />
      <center>
        <button style={{ marginTop: '30px' }} className="modal-button modal-button-blue" onClick={handleSendToAll}>Enviar</button>
      </center>
      <Modal 
        show={showModal} 
        onClose={() => setShowModal(false)} 
        success={modalSuccess} 
        message={modalMessage} 
      />




      {/* Modal de activación de Aincard */}
      {showActivateModal && (
        <div className="modal-overlay">
          <div className="email-popover-content">
            <div className='page-desc2'>Selecciona los destinatarios del correo.</div>
            <div className="mini-filter-container">
              <div className='page-desc2'>Mostrar</div>
              <label className="filter-label">
                  <input 
                      type="radio" 
                      name="filter2" 
                      value="all" 
                      checked={filter2 === 'all'} 
                      onChange={handleFilterChange2}
                      style={{marginTop: '16px'}}
                  />
                  Todos
              </label>
              <label className="filter-label">
                  <input 
                      type="radio" 
                      name="filter2" 
                      value="clients" 
                      checked={filter2 === 'clients'} 
                      onChange={handleFilterChange2}
                      style={{marginTop: '16px'}}
                  />
                  Clientes
              </label>
              <label className="filter-label">
                      <input 
                      type="radio" 
                      name="filter2" 
                      value="noclients" 
                      checked={filter2 === 'noclients'} 
                      onChange={handleFilterChange2}
                      style={{marginTop: '16px'}}
                  />
                  No clientes
              </label>
            </div>

            <div className='email-popover-list-box'>
              <div className="search-container">
                <div className="search-icon">
                    <div className="profile-image"></div>
                </div>
                <input 
                    type="text" 
                    placeholder="Nombre o email" 
                    className="search-input" 
                    value={searchTerm} 
                    onChange={handleSearch}
                />
              </div>
              <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px', alignItems: 'flex-start'}}>
                <label class="custom-checkbox" style={{width: '100%', fontSize:'16px', fontWeight: 'bold', whiteSpace: 'nowrap'}}>
                  <input 
                    type="checkbox" 
                    id="select-all" 
                    name="select-all" 
                    checked={selectAll} 
                    onChange={handleSelectAll} 
                  />
                  <span class="checkmark"></span>
                  Seleccionar todo
                </label>
              </div>
              <div style={{height:'10px'}}></div>

              <div className='scrollable'>
              {
                filteredByRadio2.map((user, index) => (
                  <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px', alignItems: 'flex-start'}}>
                    <label class="custom-checkbox" style={{width: '100%', fontSize:'16px', whiteSpace: 'nowrap'}}>
                      <input 
                        type="checkbox" 
                        id={user.uid} 
                        name={user.uid} 
                        checked={selectedUsers.includes(user)} 
                        onChange={() => handleSelectUser(user)} 
                      />
                      <span class="checkmark"></span>
                      {user.nombre} ({user.email})<b>. {userProductCounts[user.uid] ? userProductCounts[user.uid].songs + userProductCounts[user.uid].cards : 0} sonidos activados</b>
                    </label>
                  </div>
                ))
              }
              </div>

            </div>

            <div className="modal-buttons-split">
              <button className="modal-button modal-button-purple" onClick={cancelCustomSelection}>Cancelar</button>
              <div>{selectedUsers.length} seleccionados</div>
              <button className="modal-button modal-button-blue" onClick={saveCustomSelection}>Continuar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailSender;
